

































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  alphaNum,
  decimal,
  maxValue,
  minValue,
  numeric,
  required,
  maxLength,
  requiredIf,
  minLength,
  ValidationRule,
} from 'vuelidate/lib/validators'
import MixinValidation from '@/app/ui/views/BisaBelanja/Merchant/Product/mixinValidation'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Dropzone from '@/app/ui/components/Dropzone/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/ProductController'
import ProductTableVariant from '../components/ProductTableVariant/index.vue'
import ProductTextInput from '../components/ProductTextInput/index.vue'
import AddCircleIcon from '@/app/ui/assets/add_circle.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import useActionProductVariant, {
  FormVariantTypes,
  PropsTableData,
} from '../useCase'
import MultiOptionsProduct from '../components/MultiOptions/index.vue'
import { ProductCategory } from '@/domain/entities/Product'

interface Form {
  name: string
  salesPrice: number | null
  basePrice: number | null
  images: Array<Blob | null>
  stock: number | null
  weight: number | null
  length: number | null
  width: number | null
  height: number | null
  description: string
  information: string
  sku: string
  category: number[]
}

interface ValidationsInterface {
  form: {
    name: {
      required: () => ValidationRule,
      maxLength: ValidationRule
    },
    salesPrice: {
      required: ValidationRule,
      numeric: () => ValidationRule
    },
    basePrice: {
      required: ValidationRule,
      numeric: () => ValidationRule
    },
    images: {
      0: {
        required: () => ValidationRule,
      },
      1: {
        required: () => ValidationRule,
      },
      2: {
        required: boolean,
      },
      3: {
        required: boolean,
      }
    },
    stock: {
      required: ValidationRule,
      numeric: () => ValidationRule,
      alphaNum: () => ValidationRule,
      minValue: ValidationRule,
      maxValue: ValidationRule,
    },
    sku: {
      maxLength: ValidationRule,
    },
    weight: {
      required: ValidationRule,
      decimal: () => ValidationRule,
    },
    length: {
      decimal: () => ValidationRule,
      minValue: ValidationRule,
    },
    width: {
      decimal: () => ValidationRule,
      minValue: ValidationRule,
    },
    height: {
      decimal: () => ValidationRule,
      minValue: ValidationRule,
    },
    description: {
      required: () => ValidationRule,
      maxLength: ValidationRule,
    },
    information: {
      required: () => ValidationRule,
      maxLength: ValidationRule,
    },
    category: {
      required: () => ValidationRule,
      minLength: ValidationRule,
    }
  },
  formVariant: {
    required: ValidationRule,
    $each: {
      name: {
        required: ValidationRule,
        minLength: ValidationRule,
      },
      value: {
        required: ValidationRule,
        minLength: ValidationRule,
      }
    }
  }
}

@Component({
  mixins: [validationMixin, MixinValidation],
  components: {
    Dropzone,
    TextInput,
    Button,
    Modal,
    ModalAction,
    LoadingOverlay,
    ProductTableVariant,
    AddCircleIcon,
    AddIcon,
    ProductTextInput,
    TrashIcon,
    IconWarningTriangle,
    MultiOptionsProduct,
  },
})
export default class CreateMerchantProduct extends Vue {
  controller = controller
  form: Form = {
    name: '',
    salesPrice: null,
    basePrice: null,
    images: [null, null, null, null],
    stock: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    description: '',
    information: '',
    sku: '',
    category: [],
  }
  confirmationModal = false
  successModal = false
  deleteVariantModal = {
    show: false,
    index: 0,
    type: 'unit',
  }
  isValidate = false
  loaderOfCategories = [true]

  options1: ProductCategory[] = []
  options2: ProductCategory[] = []
  options3: ProductCategory[] = []

  // Table Variant
  actionVariant = useActionProductVariant()
  listErrorVariant = Array<boolean[]>([])
  hasDuplicateOptions = false
  errorMsgFormVariant = ['', '']

  created(): void {
    controller.getCategoryLevelOne()
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(100),
        },
        salesPrice: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          numeric,
        },
        basePrice: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          numeric,
        },
        images: {
          0: {
            required,
          },
          1: {
            required,
          },
          2: {
            required: false,
          },
          3: {
            required: false,
          },
        },
        stock: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          numeric,
          alphaNum,
          minValue: minValue(1),
          maxValue: maxValue(9999),
        },
        sku: { maxLength: maxLength(100) },
        weight: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          decimal,
        },
        length: { decimal, minValue: minValue(1) },
        width: { decimal, minValue: minValue(1) },
        height: { decimal, minValue: minValue(1) },
        description: { required, maxLength: maxLength(3000) },
        information: { required, maxLength: maxLength(150) },
        category: { required, minLength: minLength(3) }
      },
      formVariant: {
        required: requiredIf(() => {
          return this.formVariant.length > 0
        }),
        $each: {
          name: {
            required: requiredIf(() => {
              return this.formVariant.length > 0
            }),
            minLength: minLength(1),
          },
          value: {
            required: requiredIf(() => {
              return this.formVariant.length > 0
            }),
            minLength: minLength(1),
          },
        },
      },
    }
  }

  get isDisabledVariant2(): boolean {
    return Boolean(
      controller.formVariant &&
        controller.formVariant[0].name &&
        controller.formVariant[0].value.length > 0
    )
  }

  get headerTableVariant(): string[] {
    return controller.headerTableVariant
  }

  get formVariant(): FormVariantTypes[] {
    return controller.formVariant
  }

  get dataListTable(): (string | number | boolean | undefined)[][] {
    return controller.dataListTable
  }

  private getErrorOptionsVariant(idx: number): boolean {
    const validateFormVariant = this.$v.formVariant as Record<string, any>
    if (
      !this.hasDuplicateOptions &&
      validateFormVariant.$each[idx].value.$invalid &&
      this.isValidate
    ) {
      return true
    } else {
      return false
    }
  }

  private getErrorVariantName(idx: number): boolean {
    const validateFormVariant = this.$v.formVariant as Record<string, any>
    if (validateFormVariant.$each[idx].name.$invalid && this.isValidate) {
      this.errorMsgFormVariant[idx] = 'Variant name is required'
      return true
    } else if (this.errorMsgFormVariant[idx]) {
      return true
    } else {
      this.errorMsgFormVariant[idx] = ''
      return false
    }
  }

  private onInputVariantName(val: string, idx: number): void {
    const xIndex = idx === 0 ? 1 : 0
    this.errorMsgFormVariant = ['', '']
    if (this.formVariant && this.formVariant.length === 2) {
      if (
        this.formVariant[xIndex].name.toLowerCase() === val.toLowerCase() &&
        this.formVariant[xIndex].name !== ''
      ) {
        this.errorMsgFormVariant[idx] = 'Variant name cannot be the same'
      } else {
        this.errorMsgFormVariant[idx] = ''
      }
    } else {
      this.errorMsgFormVariant[idx] = ''
    }
  }

  private showModalDeleteVariant(index: number, type: 'unit' | 'all'): void {
    this.deleteVariantModal = {
      show: true,
      index: index,
      type: type,
    }
  }

  private dropzoneLabel(index: number): string {
    if (index === 0) return 'Main*'

    return `Image ${index}${index === 1 ? '*' : ''}`
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'MerchantProductListPage' })
  }

  private onAddVariant(): void {
    controller.setAddVariant({
      name: '',
      value: [],
      onHeader: false,
    })
  }

  private removeVariant(): void {
    if (this.deleteVariantModal.type === 'unit') {
      controller.removeOneVariant(this.deleteVariantModal.index)
    } else {
      this.clearAllVariant()
    }
    this.deleteVariantModal = {
      show: false,
      index: 0,
      type: 'unit',
    }
  }

  private clearAllVariant(): void {
    controller.clearAllVariant()
  }

  private onActionVariant(): void {
    if (this.formVariant.length === 0) {
      this.onAddVariant()
    } else {
      this.deleteVariantModal = {
        show: true,
        index: 0,
        type: 'all',
      }
    }
  }

  private onChangeTableVariant(
    val: number | string | boolean,
    propsData: PropsTableData
  ): void {
    this.actionVariant.onChangeTableVariant(val, propsData)
  }

  private onSetStatusVariant(isActive: boolean, propsData: PropsTableData): void {
    this.actionVariant.onChangeTableVariant(!isActive, propsData)
  }

  private onCreateProduct(): void {
    this.isValidate = true
    const hasErrorFormVariantName = this.errorMsgFormVariant.some(v => v.length > 0)
    const hasErrorTableVariant = this.listErrorVariant.flat().includes(true)
    if (this.$v.$invalid || hasErrorTableVariant || hasErrorFormVariantName) {
      this.confirmationModal = false
      this.$notify({
        title: 'Create Product Failed',
        text: 'Mohon cek kembali setiap kolom dan pastikan semua telah terisi',
        type: 'error',
        duration: 5000,
      })
    } else if (Utils.isIncludeEmoticon(this.form.sku)) {
      this.confirmationModal = false
      this.$notify({
        title: 'Create Product Failed',
        text: 'Kode SKU harus berisi huruf, angka dan simbol',
        type: 'error',
        duration: 5000,
      })
    } else {
      if (this.formVariant.length !== 0) {
        this.form.salesPrice = 1
        this.form.basePrice = 1
        this.form.sku = ''
        this.form.stock = 0
        this.form.weight = 0
      }

      const { listVariant } = this.actionVariant.generateVariantList(
        this.dataListTable,
        this.formVariant
      )
      controller.createProduct({
        merchantId: Number(this.$route.params.id),
        ...this.form,
        variantList: listVariant,
      })
    }
  }

  private onSelectCategory(val: number, index: number): void {
    this.form.category[index] = val
    const threeCategoryFilled = this.form.category.length >= 3
    if (threeCategoryFilled) this.form.category.splice(index, 2, val)
    if (index === 0) {
      if (threeCategoryFilled) {
        this.form.category.splice(1, 2)
      }
      this.options3 = []
      this.loaderOfCategories[1] = true
      controller.getCategories({idOne: val})
    }

    if (index === 1) {
      if (threeCategoryFilled) {
        this.form.category.splice(2, 1)
      }
      this.loaderOfCategories[2] = true
      controller.getCategories({idOne: this.form.category[0], idTwo: val})
    }
  }

  @Watch('controller.errorFetch', { deep: true })
  onErrorFetchChanged(err: string): void {
    if (err !== '') {
      this.$notify({
        title: 'Get category list failed',
        text: err,
        type: 'error',
        duration: 5000,
      })
    }
  }

  @Watch('controller.optionsCategoryOne', { deep: true })
  setOptionsCategoryOneChanged(data: ProductCategory[]): void {
    if (data.length > 0) {
      this.loaderOfCategories[0] = false
    }
    this.options1 = data
  }

  @Watch('controller.optionsCategoryTwo', { deep: true })
  setOptionsCategoryTwo(data: ProductCategory[]): void {
    if (data.length > 0) {
      this.loaderOfCategories[1] = false
    }
    this.options2 = data
  }

  @Watch('controller.optionsCategoryThree', { deep: true })
  setOptionsCategoryThree(data: ProductCategory[]): void {
    if (data.length > 0) {
      this.loaderOfCategories[2] = false
    }
      this.options3 = data
  }

  @Watch('formVariant', { deep: true })
  setFormVariant(data: FormVariantTypes[]): void {
    this.actionVariant.setFormVariant(data, this.dataListTable)
  }

  @Watch('dataListTable', { deep: true })
  setDataListTable(
    data: (string | number | boolean | undefined)[][]
  ): void {
    // Set init ListError
    this.listErrorVariant = this.actionVariant.setValidateTable(data, this.formVariant)
  }

  @Watch('controller.statusCreateUpdateProduct')
  onStatusCreateUpdateProductChange(status: string): void {
    if (status !== '') {
      if (status === EventBusConstants.CREATE_PRODUCT_SUCCESS) {
        this.confirmationModal = false
        this.successModal = true
      }
    }
  }

  beforeDestroy(): void {
    controller.setStatusCreateUpdateProduct('')
    controller.clearAllVariant()
  }
}
